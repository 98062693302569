@import '../../styles/variables.scss';

.app-header {
  &__container {
    z-index: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background: white;
    background: #ffffff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.04);

    [role='button'] {
      box-shadow: initial !important;
      border: 0;
      background-color: transparent !important;
    }

    [role='menu'] {
      background: #ffffff;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 10px 30px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 0;
      border: 0;

      button {
        width: 100%;
        height: 36px;
      }
      button + button {
        margin-top: 15px;
      }
    }

    .header {
      font-weight: bold;
      padding: 15px 20px;
    }

    .link {
      font-size: 16px;
      color: #2b2d42;
      padding-left: 20px;
    }

    .link-inner {
      padding: 13px 20px 13px 0;
      white-space: nowrap;
    }

    .separator {
      border-top: thin solid #eee;
    }

    .buttons {
      padding: 20px;

      button {
        white-space: nowrap;
      }
    }
  }

  &__mobile-holder {
    display: flex;
    width: max-content;
    justify-content: space-between;
  }

  &__body {
    width: 100%;
    padding: 0 30px;
    margin: 0 auto;

    display: flex;

    button:focus {
      outline: 0;
    }

    &__home-button {
      margin: 17px 0;
      padding-left: 0px !important;

      @include mobileDevice {
        margin: 12px 0 6px 0;
      }
    }

    &__home-button-container {
      border-right: 1px solid $color-gray-separator-redesign;
    }

    &__link {
      margin: 18px;
      @include mobileDevice {
        margin-left: 0;
      }
      cursor: pointer;
    }

    &__link-disabled {
      cursor: auto;
    }

    &__close-button {
      width: 165px;
    }

    &__progress-bar {
      display: flex;
      &__part {
        display: flex;
        @include desktop {
          margin-right: 40px;
        }
        line-height: 150%;
        color: $text-teal-redesign;

        &-current {
          font-weight: 500;
          font-size: 12px;
          @include mobileDevice {
            padding: 20px 27px;
            border-top: 1px solid $color-gray-separator-redesign;
          }
        }
        &-completed {
          @include mobileDevice {
            display: none;
          }
          font-weight: bold;
          font-size: 12px;
        }
        &-incompleted {
          @include mobileDevice {
            display: none;
          }
          font-weight: normal;
          font-size: 12px;
          color: $text-gray-redesign;
        }

        &__title {
          padding-top: 7px;
        }

        &__check {
          width: 15px;
          height: 11px;
          margin-top: 4px;
        }

        &__circle {
          min-width: 32px;
          height: 32px;
          border-radius: 16px;
          text-align: center;
          padding-top: 6px;
          margin-right: 8px;
          background: $text-teal-redesign;

          &-current {
            color: white;
          }

          &-incompleted {
            border: 1px solid;
            background: white;
            color: $text-teal-redesign;
            border-color: $color-gray-separator-redesign;
          }
        }
      }
      @include mobileDevice {
        display: none;
      }
      &__mobile {
        margin: 0;
        @include desktop {
          display: none;
        }
        @include mobileDevice {
          display: inline;
        }
      }
    }
  }
}

.link {
  cursor: pointer;
  &:hover,
  &--active {
    background: #eee;
  }
  &--active {
    margin-top: -1px;
  }
}

[class^='dropdown---menu-item-wrapper'] {
  padding: 0;
}

[class^='dropdown---menu-item'] {
  padding: 0 !important;
}
