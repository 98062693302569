@import '../../styles/variables.scss';

.test-processing-page {
  &__card {
    align-items: center;
    display: flex;
    height: calc(100vh - 70px);

    @include desktop {
      height: 60vh;
    }
  }
}
