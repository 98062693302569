@import '../../styles/variables.scss';

.landing-header {
  &__logo {
    position: relative;
    left: -13px;
  }

  &__button-back {
    color: $text-teal-redesign;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    padding-top: 48px;
    margin-bottom: -12px;
  }

  &__arrow-back {
    width: 7.4px;
    height: 12px;
    margin: 2px 8px 0 0;
  }

  &__reset-password-email {
    font-weight: bold;
    color: $text-gray-redesign;
  }

  .title {
    padding-top: 40px;
  }

  .subtitle {
    padding-top: 32px;
  }

  .description {
    padding-top: 12px;
  }
}
