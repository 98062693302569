@import '../../styles/variables.scss';

.horizontal-test-navigation {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);

  &__container {
    display: flex;
    overflow-x: hidden;
    max-width: 1010px;
    margin-left: auto;
    margin-right: auto;
  }

  &__item {
    min-width: 300px;
    transition: all 300ms;
    padding-left: 17px;
    position: relative;

    @include desktop {
      padding-left: 0;
    }

    & > div {
      padding: 19px 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;

      @include desktop {
        margin-right: 50px;
      }

      .icon-question-mark {
        background: $text-black-redesign;
        margin: 1px 0 0 10px;
        min-width: 16px;
        height: 16px;
        font-weight: bold;
        font-size: 8px;
        line-height: 16px;
        color: white;
        text-align: center;
        border-radius: 8px;
      }
    }

    &--active > div {
      font-weight: bold;
      font-size: 14px;
      line-height: 130%;
      color: $text-black-redesign;
    }
  }
}
.navigation-item {
  display: flex;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: $text-gray-redesign;
  &__image {
    margin: 5px 0 0 30px;
    height: 10px;
    width: 5px;
  }
}
.react-tooltip-lite {
  display: flex;
  background: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  width: 600px !important;

  &-arrow {
    border-color: #fff;
  }

  &__content {
    padding: 20px;
  }
}

.tooltip {
  display: flex;
}
