@import '../../styles/variables.scss';

.header {
  background: $container-background;
}

.development-guide-page {
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;

  &__title-with-right-margin {
    margin: 5px auto 0 0;
  }

  &__back-title {
    color: $text-gray-redesign;
    font-weight: bold;
    font-size: 14px;
    margin: 0 0 24px 16px;
    padding-top: 24px;
  }

  &__circle {
    background-color: #e0f1f4;
    border-radius: 24px;
    margin: 0 24px 0 auto;
    width: 48px;
    height: 48px;
  }

  &__score {
    border: 1px solid $color-stroke;
    box-sizing: border-box;
    border-radius: 80px;
  }

  &__link {
    display: flex;
    border-bottom: 1px solid $color-gray-separator-redesign;
    margin-bottom: 64px;
    padding: 16px 0 16px 128px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  &__icon {
    display: block;
    height: 20px;
    width: auto;
    margin: 14px auto;
  }

  .card {
    min-height: initial;

    &__header {
      border-width: 0px;
      padding: 0 0 24px 0;
      &__title {
        display: inline;
        @include desktop {
          display: flex;
          flex-direction: row;
        }

        justify-content: space-between;
        font-weight: bold;
        font-size: 32px;
        line-height: 130%;
        color: $text-black-redesign;
      }

      &__title__leading {
        display: flex;
        &__text {
          font-weight: bold;
          font-size: 32px;
          line-height: 130%;
          padding-top: 10px;
          @include mobileDevice {
            padding-top: 25px;
          }
        }
      }
      &__subtitle {
        padding-bottom: 10px;
        @include desktop {
          padding: 0 50px 0 0;
        }
        font-size: 16px;
        font-weight: normal;
        line-height: 150%;
        color: $text-gray-redesign;
      }
    }

    &__section {
      padding: 40px;
      border-width: 1px;
      box-sizing: border-box;
      border-radius: 15px;
      margin-bottom: 16px;
      border-color: $color-stroke;
    }

    &__footer {
      padding-left: 0;
      font-weight: bold;
      border-top: 0;
    }

    &__footer-href {
      color: $text-gray-redesign;
    }

    box-shadow: 0 0 0 0;
    border-width: 0;
    border-radius: 0;
  }

  .vertical-navigation {
    border-width: 1px;
    border-radius: 5px;
    border-color: $color-stroke;
  }

  &__title-with-icon {
    display: flex;
  }

  &__title-without-icon {
    padding-top: 64px;
    @include mobileDevice {
      padding-top: 85px;
    }
  }

  &__header {
    margin-top: 20px;
    margin-left: 17px;
    padding-bottom: 20px;

    @include desktop {
      margin: 0 auto;
      padding: 0 120px;
      text-align: center;
      padding-bottom: 64px;
    }

    @include mobileDevice {
      margin-top: 0;
    }

    &__title {
      font-weight: bold;
      font-size: 32px;
      color: $text-black-redesign;
      text-align: center;
    }

    &__subtitle {
      margin-top: 16px;
      font-style: normal;
      line-height: 150%;
      font-weight: normal;
      color: $text-gray-redesign;
      padding-right: 10px;

      @include desktop {
        padding: 0 300px;
      }
    }
  }

  &__body {
    display: block;
  }

  &__navigation-content {
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }

  &__navigation {
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);

    @include desktop {
      margin: 0 0 30px 0;
    }

    @include desktop {
      width: 100%;
    }

    position: sticky;
    top: 80px;

    @include mobileDevice {
      top: 150px;
    }
  }

  .separator {
    height: 1px;
    background: $color-stroke;
    margin-bottom: 47px;
  }

  .navigation-item {
    font-size: 16px;
  }

  &__content {
    max-width: 100%;
    width: 100%;

    margin: 0 30px;

    @include desktop {
      margin-left: 30px;
      margin-right: 30px;
      max-width: 1050px;
    }

    &__container {
      display: flex;
    }

    &__logo {
      display: none;

      @include desktop {
        border: 1px solid $color-gray-separator-redesign;
        box-sizing: border-box;
        margin-top: 8px;

        border-radius: 50%;
        width: 96px;
        height: 96px;
        color: $color-brand;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 30px;
      }
    }
  }

  .external-resource {
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: $text-black-redesign;
    border-width: 0 0 1px 0;
    border-color: $color-stroke;

    &__link {
      color: $text-gray-redesign;
      font-weight: bold;
      font-size: 12px;
      line-height: 150%;
      margin-right: 12px;
    }
    &__link-image {
      width: 5px;
      height: 9px;
      margin-left: 5px;
    }

    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include phone {
      flex-direction: column;

      &__last-access {
        margin-top: 10px;
        margin-left: initial;
      }

      a {
        margin-top: 10px;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .score {
    &__container {
      padding: 16px 0;
    }
  }

  .links {
    :last-child {
      border-width: 0px;
    }
  }
}

.footer {
  padding: 32px 0;
  background: $container-background;
  .block {
    width: 375px;
    margin: 0 auto;
  }
}
