@import '../../styles/variables.scss';

.section-header {
  margin-bottom: 7px;
  background: transparent;

  @include phone {
    margin-top: 0px;
  }
  @include tablet {
    margin-top: 0px;
  }

  .section {
    &__title {
      margin-right: 25px;
      font-weight: bold;
      font-size: 32px;
      padding-bottom: 33px;
      color: $text-black-2;

      display: flex;
      flex-direction: column;
      @include desktop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .bg-brand {
        @include phone {
          margin-top: 20px;
        }
        @include tablet {
          margin-top: 20px;
        }
      }
    }

    &__subtitle {
      font-size: 14px;
      line-height: 150%;
      color: $text-gray-redesign;
      padding-bottom: 33px;
      margin-top: -20px;
      @include desktop {
        width: 40%;
      }
    }

    &__annotationTitle {
      font-weight: bold;
      font-size: 16px;
      color: $color-brand;
      padding-bottom: 4px;
    }

    &__backButton {
      margin-left: 25px;
      font-weight: bold;
      font-size: 14px;
      color: $text-gray;
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: thin solid $color-gray-separator-redesign;

      @include desktop {
        margin-left: -50px;
        padding: 0 50px 40px 50px;
      }
    }

    &__backLink {
      :first-child {
        margin-top: 0px;
      }
      text-decoration: none;
      color: $text-gray-redesign;
      &:hover {
        text-decoration: underline;
      }
    }

    &__backArrow {
      margin-right: 16px;
    }
  }

  :first-child {
    margin-top: 40px;
  }
}
