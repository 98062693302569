@import '../../styles/variables.scss';

.tutorial {
  display: flex;
  flex-wrap: wrap;
  max-width: 100vw;

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 100%;
    margin-bottom: 24px;
  }

  &__video {
    margin: 0 32px 32px 0;
    max-width: 30vw;
    width: 100%;
    height: 288px;
    &__iframe {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
}
