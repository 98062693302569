@import '../../styles/variables.scss';

.checkbox {
  margin-right: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid $color-brand;
  border-radius: 3px;

  &--checked {
    background: $color-brand;
  }

  &__icon {
    margin: 3px 0 0 3px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-1px) translateX(-1px);

    & img {
      width: 60%;

      @include desktop {
        width: initial;
      }
    }
  }
}
