@import '../../styles/variables.scss';

.account-login-page {
  display: flex;
  justify-content: center;
  align-items: center;

  .auth-card {
    width: 100%;

    @include desktop {
      margin: 30px;
      width: 600px;
    }

    &__header {
      padding: 15px;
      border-bottom: 1px solid $border-grey-light;

      @include desktop {
        padding: 45px;
      }

      @media (max-width: 1024px) {
        padding: 25px;
      }

      .logo {
        margin: 0 auto;
        display: block;
      }
    }

    &__body {
      @media (max-width: 1024px) {
        padding: 25px;
      }

      @media (min-width: 1025px) {
        padding: 60px;
      }
    }
  }
}
