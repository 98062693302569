@import '../../styles/variables.scss';

.development-guide-layout {
  padding-top: 155px;

  @include desktop {
    padding-top: 76px;
  }

  &.full-page-background-layout {
    background-position: top center;
  }

  .abc {
    opacity: 0.5;
  }

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 76px;
  }

  position: relative;

  .popup__body {
    &__container {
      border-radius: 20px;
      background: white;

      @include mobileDevice {
        margin: 30px;
      }

      &__content {
        @include desktop {
          width: 586px;
        }

        &__bullet {
          margin: 0 auto 24px auto;
          background: $color-yellow-background;
          width: 96px;
          height: 96px;
          border-radius: 48px;
        }

        &__image {
          width: 44px;
          height: 44px;
          margin: 26px 0 0 26px;
        }

        &__title {
          text-align: center;
          font-weight: bold;
          font-size: 32px;
          line-height: 130%;
          color: $text-black-redesign;
        }
        &__desc {
          margin: 12px 0 40px 0;
          font-size: 16px;
          line-height: 150%;
          color: $text-gray-redesign;
          text-align: center;
        }
      }
    }
  }
}
