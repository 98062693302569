@import '../../styles/variables.scss';

.registration-form {
  margin-top: 29px;

  &__icon {
    margin-top: 1px;
    height: 9.6px;
  }

  &__name {
    display: flex;
    :first-child {
      margin-right: 26px;
    }
  }

  &__submit-text {
    margin-right: 8px;
  }

  a {
    color: $text-gray-redesign;
    font-weight: bold;
  }
}
