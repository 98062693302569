@import '../../styles/variables.scss';

.checklist {
  &__item {
    padding: 14px 0;
    border-color: $border-grey-light;
    border-bottom-style: solid;
    border-bottom-width: thin;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left;
    font-size: 12px;
    color: $text-gray-redesign;

    @include desktop {
      align-items: initial;
      font-size: initial;
    }

    &:last-of-type {
      border-color: transparent;
    }
  }

  &__item-deactivated {
    opacity: 0.5;
    -moz-opacity: 0.5;
    -webkit-opacity: 0.5;
    filter: alpha(opacity=50);
  }
  margin-bottom: 40px;
}
