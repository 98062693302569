@import '../../styles/variables.scss';

.login-form {
  margin-top: 29px;

  &__icon {
    margin-top: 2px;
    height: 9.6px;
  }

  &__forgot-password {
    position: absolute;
    right: 0px;

    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
  }

  &__password {
    position: relative;
    width: 100%;

    &__button {
      position: absolute;
      top: 45px;
      right: 20px;
      font-weight: bold;
      font-size: 12px;
      color: $text-teal-redesign;
    }

    &__button:hover {
      cursor: pointer;
    }
  }

  &__submit-text {
    margin-right: 8px;
  }

  a {
    color: $text-gray-redesign;
    font-weight: bold;
  }

  &__with-button {
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
