@import '../../styles/variables.scss';

.page-role-competency-test {
  position: relative;
  padding-top: 155px;

  @include desktop {
    padding-top: 82px;
  }

  &__header {
    background: $container-background;

    &__title {
      font-weight: bold;
      font-size: 32px;
      line-height: 130%;
      color: $text-black-redesign;
      margin-bottom: 8px;
    }
    &__desc {
      font-size: 14px;
      line-height: 150%;
      color: $text-gray-redesign;
      margin-top: 0px;
      padding-bottom: 56px;
    }
  }

  &__width-constraint {
    text-align: center;
    max-width: 1010px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 17px;

    @include desktop {
      padding: 0;
      padding-top: 56px;
    }

    &__desc {
      font-weight: bold;
      font-size: 20px;
      line-height: 130%;
      color: $text-black-redesign;
      text-align: left;
      margin-bottom: 24px;
    }

    &__form {
      margin-bottom: 120px;
    }
  }

  & .font-bold {
    font-size: 14px;

    @include desktop {
      font-size: initial;
    }
  }

  .test-progress-control-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    @include desktop {
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
      width: initial;
    }
  }
}

.popup-exit {
  bottom: -120px;
  height: auto;
}

.popup-exit__body {
  z-index: 3;
  &__container {
    border-radius: 20px;
    background: white;
    &__content {
      margin: 32px;
      &__bullet {
        margin: 0px auto 24px auto;
        background: $color-yellow-background;
        width: 96px;
        height: 96px;
        border-radius: 48px;
      }

      &__image {
        width: 44px;
        height: 44px;
        margin: 26px 0 0 26px;
      }

      &__title {
        font-weight: bold;
        font-size: 20px;
        line-height: 130%;
        text-align: center;
        color: $text-black-redesign;
      }
      &__desc {
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: $text-gray-redesign;
        margin: 16px 0 32px 0;
      }
      &__button-exit {
        margin-bottom: 12px;
      }
      &__button-continue {
      }
    }

    @include mobileDevice {
      margin: 40px 20px;
    }
  }
}
