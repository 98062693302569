@import '../../styles/variables.scss';

.landing-footer {
  &__footer {
    border-top: 1px solid $color-gray-separator-redesign;
    padding-top: 28px;
    color: $text-gray-redesign;
    font-size: 14px;
    line-height: 174%;
  }
}
