@import '../../styles/variables.scss';

.employee {
  &__list {
    max-width: 100vw;
    margin: 0 auto 40px 0;
  }

  &__test {
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    box-sizing: border-box;

    margin-bottom: 16px;

    &:hover {
      cursor: pointer;
    }

    box-shadow: 0 12px 24px rgba(51, 84, 90, 0.06);
    border-radius: 5px;
  }

  &__testTitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    padding: 32px 0 32px 24px;
  }

  &__trailing {
    display: flex;
    flex-direction: row;
  }

  &__testDate-taken {
    border-right: 1px solid $color-gray-separator-redesign;
    margin: 22px 0;
  }

  &__test-status {
    margin: 22px 0 22px 70px;
  }

  &__testDate {
    font-weight: 500;
    font-size: 12px;

    &__taken {
      padding: 0 70px 9px 80px;
      color: $text-gray-redesign;
    }

    &__not-taken {
      padding: 38px 95px 0 80px;
      font-size: 12px;
      color: $text-gray-redesign;
    }

    &__date {
      color: $text-gray-redesign;
      vertical-align: text-top;
      padding: 0 80px;
    }

    &__status {
      color: $text-gray-redesign;
      padding-bottom: 9px;
    }

    &__status-inprogress {
      color: $color-yellow;
    }
    &__status-completed {
      color: $color-teal;
    }
  }

  &__inprogress {
    height: 16px;
    width: 16px;
    margin-right: 11px;
  }

  &__completed {
    width: 14px;
    height: 11px;
    margin: 3px 11px 0 0;
  }

  &__calendar {
    width: 13px;
    height: 14px;
    margin-right: 11px;
  }

  &__disclosure {
    width: 48px;
    height: 48px;
    margin: 20px 23px 0 0;
    padding: 18px 20px;
    border: 1px solid $color-gray-separator-redesign;
    box-sizing: border-box;
    border-radius: 3px;
  }

  &__separator {
    width: 1px;
    background: $color-stroke;
    margin: 20px 0;
  }

  &__spacer {
    width: 280px;
  }

  &__spacer_longer {
    width: 106px;
  }

  &__score {
    &__container {
      display: flex;
      flex-direction: row;
    }

    &__percentage {
      color: $color-teal;
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
    }

    &__separator {
      width: 4px;
      height: 4px;
      background: $color-stroke;
      margin: 6px 8px 0 8px;
      border-radius: 50%;
    }

    &__result {
      font-weight: 500;
      font-size: 14px;
      color: $text-gray;
    }
  }
}
