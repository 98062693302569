.popup {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  background-size: cover;
  background-position: top;

  &__body {
    display: flex;
    margin: 10% auto auto auto;
  }
}
