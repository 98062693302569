@import '../../styles/variables.scss';

.welcome-page {
  display: flex;
  justify-content: center;
  align-items: center;

  .welcome-card {
    width: 100%;
    padding: 90px 30px;

    @include desktop {
      width: 770px;
      padding: 32px;
      margin: 30px;
      margin-top: 120px;
    }
  }

  .welcome__item:last-child {
    .welcome__menuRowDivider {
      height: 0px;
    }
  }

  .welcome {
    &__title {
      padding-bottom: 16px;

      font-weight: bold;
      font-size: 32px;
      line-height: 100%;
    }

    &__desc {
      margin: 0px 0px 31px 0px;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $text-gray;
    }

    &__item {
      &:hover {
        cursor: pointer;
      }

      &:last-child {
        margin-top: 13px;
      }
    }

    &__inline {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__divider {
      margin-left:-32px;
      width: 109%;
      height: 1px;
      margin-bottom: 15px;
      background: #ededed;
    }

    &__menuRow {
      margin-top: 24px;
    }

    &__menuRowDivider {
      top: 29px;
    }

    &__menuRowDivider {
      height: 1px;
      background: #ededed;
    }

    &__item:hover {
      text-decoration-line: underline;
    }

    &__menuRowTitle {
      font-weight: 500;
      font-size: 16px;
      font-family: Roboto;
      color: $text-black-2;
      padding-bottom: 12px;
    }

    &__menuRowDesc {
      font-weight: normal;
      font-size: 12px;
      line-height: 100%;
      color: $text-gray;
      padding-bottom: 25px;
      padding-right: 104px;
      padding-top: 0px;
    }

    &__disclosure {
      margin: auto 5px auto 0px;
    }
  }
}
