@import '../../styles/variables.scss';

.page__roles-selection {
  position: relative;
  max-width: 1170px;
  padding: 0 17px;
  margin: 80px auto 105px auto;

  @include mobileDevice {
    margin-top: 180px;
  }

  @include desktop {
    padding: 0;
    margin-top: 138px;
  }

  @include phone {
    &__header {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    line-height: 130%;
    margin-top: 56px;
  }
}

.page__roles {
  overflow: hidden;
  position: relative;
  background-color: $container-background;

  &__footer {
    height: 80px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
  }
}

.page__roles-popup__body {
  &__container {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background: white;

    @include mobileDevice {
      border-radius: 20px;
      margin: 40px 20px;
    }

    &__content {
      @include desktop {
        width: 586px;
      }
    }
  }

  &__image {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    @include desktop {
      width: 50%;
      max-height: 604px;
      display: inline;
      background-size: cover;
      background-image: url('../../assets-shared/select-role.png');
    }
    display: none;
  }
}

.agenda-card {
  margin: 48px;
  box-shadow: 0 0 0 0;
  @include mobileDevice {
    margin: 25px;
  }
}

.agenda {
  margin-bottom: 40px;
  &__title {
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 1rem;
    color: $text-black-redesign;
  }

  &__desc {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 2.5rem;
    color: $text-gray-redesign;
  }

  &__item {
    position: relative;
    padding-left: 72px;
    margin-bottom: 32px;
  }

  &__itemTitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 0.25rem;
    color: $text-black-redesign;
  }

  &__itemDesc {
    font-weight: normal;
    font-size: 12px;
    padding-top: 1px;
    color: $text-gray-redesign;
  }

  &__bullet {
    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;
    height: 3rem;
    background: $color-bullet-background;
    color: $color-brand;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
  }
}
