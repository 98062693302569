@import '../../styles/variables.scss';

.detail {
  max-width: 100vw;
  margin-right: auto;

  &__test {
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 5px;

    margin-bottom: 16px;

    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 5px;

    &:hover {
      cursor: pointer;
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      padding: 31px 0px 31px 24px;
      color: $text-black-redesign;
    }

    &__startTraining {
      border: 1px solid $color-gray-separator-redesign;
      box-sizing: border-box;
      border-radius: 3px;

      margin: 20px 32px;
      padding: 16px 42px;

      font-weight: bold;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.03em;
      color: $text-gray-redesign;
    }
  }
}
