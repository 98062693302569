$text-black: #2b2d42;
$color-brand: #59aeb1;
$text-black-2: #202133;
$color-bullet-background: rgba(79, 160, 162, 0.12);
$color-section-header-background: #f6f6f6;
$border-grey-light: #eeeeee;
$color-danger: #ff4949;
$color-red: #f03c3c;
$text-gray: #585757;
$color-stroke: #ededed;
$color-teal: #4fa0a2;

$text-teal-redesign: #3ba6b9;
$text-black-redesign: #141618;
$text-gray-redesign: #6c7072;
$text-teal-low-opacity: #eaf4f4;
$color-gray-separator-redesign: #dae5ec;
$container-background: #f5fafd;
$text-red-redesign: #cd4545;
$color-yellow: #cda745;
$color-red-light: #faecec;
$color-yellow-background: #FFF5DD;

$phone-width: 320px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin mobileDevice {
  @media (min-width: #{$phone-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin phone {
  @media (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.title {
  font-weight: bold;
  font-size: 32px;
  line-height: 130%;
  color: $text-black-redesign;
}

.subtitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
  color: $text-black-redesign;
}

.description {
  font-size: 16px;
  line-height: 150%;
  color: $text-gray-redesign;
}
