@import '../../styles/variables.scss';

.horizontal-navigation {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  box-shadow: 0 0 0 0;

  &-item {
    width: 100vh;
    padding: 24px 0;
    text-align: center;
    border-bottom: 2px solid transparent;
    transition: all 300ms;
    color: $text-gray-redesign;
    text-decoration: none;

    font-weight: normal;
    font-size: 16px;
    line-height: 150%;

    &--active {
      font-weight: bold;
      color: $color-teal;
    }

    &:hover,
    &--active {
      color: $color-teal;
      border-bottom: 2px solid $color-brand;
    }
  }
}
