@import '../../styles/variables.scss';

.lastTesting {
  display: flex;

  &__header {
    max-width: 100vw;
    @include desktop {
      margin: 50px 0 64px 0;
    }
    @include mobileDevice {
      margin: 20px;
    }
    background-color: white;
    box-shadow: 0px 12px 24px rgba(33, 103, 116, 0.06);
    border-radius: 5px;

    &__title {
      padding: 40px 0 8px 32px;

      @include mobileDevice {
        padding: 20px 20px 8px 20px;
      }

      font-weight: bold;
      font-size: 32px;
      line-height: 130%;
    }

    &__body {
      padding: 0 32px 40px 32px;
      @include desktop {
        display: flex;
      }
      @include mobileDevice {
        padding: 0 20px 20px 20px;
      }
      &__desc {
        font-size: 16px;
        line-height: 150%;
        @include desktop {
          padding-right: 117px;
        }
        color: $text-gray-redesign;
      }
      &__button {
        @include mobileDevice {
          margin-top: 20px;
        }
        @include desktop {
          max-width: 187px;
          margin-left: auto;
        }
      }
    }
  }

  &__list {
    max-width: 100vw;
    margin-left: 0;
    margin-right: auto;

    @include mobileDevice {
      max-width: 100%;
      margin: 0 0;
    }
  }

  &__spacer {
    margin-left: 40px;
    @include desktop {
      margin-left: 305px;
    }
  }

  &__title {
    color: $text-black-redesign;
    font-weight: bold;
    font-size: 20px;
    line-height: 130%;
    margin-bottom: 21px;

    @include mobileDevice {
      margin-left: 20px;
    }
  }

  &__test {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background: white;
    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 5px;

    margin-bottom: 16px;

    &:hover {
      cursor: pointer;
    }

    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
  }

  &__testTitle {
    font-weight: bold;
    font-size: 16px;
    padding: 40px 20px 40px 24px;
  }

  &__trailing {
    display: flex;
    flex-direction: row;
  }

  &__testDate__taken {
    color: $text-gray;
    padding-top: 31px;
    padding-bottom: 8px;
    font-size: 12px;
  }

  &__testDate__date {
    font-weight: 500;
    font-size: 14px;
    color: $text-gray;
  }

  &__backToTest {
    border: 1px solid $color-brand;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    margin: 28px 24px 0px 100px;
    padding: 12px 32px 0px;

    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.12em;
    color: $color-brand;
  }

  &__score {
    margin-right: 13px;
  }
}

.danger {
  color: red;
}
