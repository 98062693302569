@import '../../styles/variables.scss';

.emptyMessage {
  color: $text-gray-redesign;
  background-color: white;
  font-size: 16px;
  line-height: 150%;
  width: 100%;
  height: 70vh;
  padding-top: 35vh;
  padding-bottom: 35vh;
  text-align: center;
  box-shadow: 0 12px 24px rgba(33, 103, 116, 0.06);
  border-radius: 5px;
}
