@import '../../styles/variables.scss';

.card {
  background: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 10px 30px 0 rgba(0, 0, 0, 0.1);

  @include desktop {
    min-height: calc(100vh - 70px);
  }
  
  @include desktop {
    border-radius: 10px;
    min-height: initial;
  }

  &__header {
    border-bottom: 1px solid $border-grey-light;
    padding: 20px;

    &__title {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 15px;
      }
    }

    &__subtitle {
      margin-top: 10px;
    }
  }

  &__footer {
    border-top: 1px solid $border-grey-light;
    padding: 20px;
    display: flex;
    align-items: center;
    margin-top: -1px;
  }

  &__title {
    font-size: 21px;
    font-weight: 600;
  }

  &__section {
    padding: 30px;
    border-bottom: 1px solid $border-grey-light;
  }

  &__section:last-of-type {
    border-bottom-color: transparent;
  }
}
