.video-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.85);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 350ms;

  &.video-overlay--visible {
    opacity: 1;
  }

  video {
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
  }

  .video-box {
    max-width: 640px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 10px 55px 10px rgba(0, 0, 0, 0.75);
    position: relative;
    transform: translateY(300px);
    transition: all 350ms;
  }

  &.video-overlay--visible .video-box {
    transform: translateY(0);
  }

  .close-video {
    background: white url('./icn-close.jpg') center no-repeat;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 20px;
    transform: translate(40%, -40%);
    cursor: pointer;
  }
}
