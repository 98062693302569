@import '../../styles/variables.scss';

.sidebar {
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 2;
  justify-content: space-between;
  filter: drop-shadow(8px 0px 24px rgba(33, 103, 116, 0.08));

  @include desktop {
    min-height: 100vh;
    min-width: 297px;
    position: fixed;
    top: 0;
    left: 0;
  }

  @include mobileDevice {
    justify-content: start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &__dropdown-button {
      @include desktop {
        display: none;
      }
      display: inline;
      margin: 35px 40px 0 0;
    }

    &__close {
      width: 14px;
      height: 14px;
    }
    &__burger {
      width: 18px;
      height: 12px;
    }
  }

  &__menu {
    @include mobileDevice {
      &__open {
        display: block;
      }
      &__closed {
        display: none;
      }
    }
  }

  &__logo {
    margin: 40px 48px;
    @include mobileDevice {
      margin: 24px 24px 10px 24px;
    }
  }

  &__unselected {
    color: $text-gray-redesign;
    margin: 0 20px;
    padding: 0;
  }

  &__tab {
    display: flex;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    padding: 25px 0;
    border-bottom: 1px solid $color-gray-separator-redesign;

    &__img-holder {
      min-width: 40px;
      height: 20px;
      text-align: center;
    }
    &__img {
      padding-right: 15px;
    }
    &__img-external {
      width: 15px;
      height: 15px;
      margin-top: 2px;
    }

    &__img-dropdown {
      width: 15px;
      height: 15px;
      margin: 4px 30px 0 0;
    }
    &__title {
      padding-top: 2px;
    }
    &__title-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__selected {
    background-color: $text-teal-redesign;
    color: white;
    padding-left: 20px;
  }

  &__link {
    text-decoration: none;
    :hover {
      cursor: pointer;
    }
  }

  &__signout {
    padding: 37px 105px 50px 105px;
    font-weight: bold;
    font-size: 12px;
    color: $text-red-redesign;
    border-top: 1px solid $color-gray-separator-redesign;

    @include mobileDevice {
      padding-left: 60px;
    }

    &__text {
      @include mobileDevice {
        width: 100%;
        text-align: left;
      }
    }

    @include mobileDevice {
      &__open {
        display: block;
      }
      &__closed {
        display: none;
      }
    }
  }

  &__separator {
    height: 100%;
  }
}
