@import '../../styles/variables.scss';

.login-page-layout {
  @include desktop {
    display: flex;
  }

  &__container {
    @include desktop {
      width: 50%;
    }

    &__content {
      max-width: 470px;
      margin: 100px auto;
    }
  }

  &__image {
    @include desktop {
      width: 50%;
      display: inline;
      min-height: 100vh;
    }
    display: none;
  }
}
