@import '../../styles/variables.scss';

.cluster-role {
  &__circle {
    background-color: #e0f1f4;
    border-radius: 24px;
    margin: 0 24px;
    min-width: 48px;
    height: 48px;
  }

  &__icon {
    display: block;
    height: 20px;
    width: auto;
    margin: 14px auto;
  }
}

.cluster-role-card {
  transition: all 300ms ease-in-out;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  // border-left: 10px solid transparent;

  &__header {
    padding: 32px;
    @include mobileDevice {
      padding: 20px;
    }
    position: relative;
    cursor: pointer;
    display: flex;
    &__title {
      font-weight: bold;
      font-size: 18px;
      line-height: 130%;
      color: $text-black-redesign;
      margin-bottom: 6px;
    }

    &__subtitle {
      font-size: 12px;
      line-height: 150%;
      color: $text-gray-redesign;
    }

    &__radio-content {
      background: $text-teal-redesign;
      position: relative;
      left: 2px;
      top: 2px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }

    &__radio {
      margin-top: 14px;
      min-width: 16px;
      height: 16px;
      border-radius: 8px;
      border: 1px solid $text-teal-redesign;
    }

    &__border {
      border-bottom: $color-gray-separator-redesign 1px solid;
    }

    &__arrow {
      margin-top: 19px;
      width: 14px;
      height: 8px;
      margin-left: auto;
    }
  }
  /**
   * Container
   */
  &:hover,
  &--expanded {
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
  }

  /**
   * Body
   */
  &__body {
    padding: 40px 72px;
    @include mobileDevice {
      padding: 20px 30px;
    }
    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      color: $text-black-redesign;
      margin-bottom: 16px;
    }
    &__desc {
      font-size: 14px;
      line-height: 150%;
      margin-bottom: 32px;
      color: $text-gray-redesign;
    }

    &__title-competencies {
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      color: $text-black-redesign;
      &:last-of-type {
        margin-top: 40px;
      }
    }
  }

  &:hover &__body,
  &--expanded &__body {
    border-bottom-color: transparent;
  }

  &--collapsed &__body {
    border-bottom: thin solid $border-grey-light;
  }

  &__footer {
    padding: 15px;
    border-top: thin solid $border-grey-light;

    & > * {
      margin-bottom: 0;
    }
  }

  /**
   * Content & other components
   */
  &__two-col-text {
    padding-left: 20px;
    column-count: 2;
    color: $text-gray-redesign;
    font-size: 14px;
    line-height: 150%;
  }

  &__li {
    margin-bottom: 16px;
    margin: 0 24px 16px 0;
    color: #cfcfd0;
    &__text {
      color: $text-gray-redesign;
    }
  }

  &__icon-close {
    background: url('./assets/icn-close.jpg');
    width: 16px;
    height: 16px;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
}
