@import '../../styles/variables.scss';

.password-request-form {
  margin-top: 29px;

  &__submit-text {
    margin-right: 8px;
  }

  &__icon, &__icon-flipped {
    margin-top: 2px;
    height: 9.6px;
  }

  &__icon-flipped {
    transform: scaleX(-1);
    margin: 1px 8px 0 0;
  }

  a {
    color: $text-gray-redesign;
    font-weight: bold;
  }
}
