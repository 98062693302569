@import '../../styles/variables.scss';

.test {
  &__footer {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: space-between;
    height: 80px;
    @include mobileDevice {
      height: 106px;
    }
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    &__back-button {
      margin: 16px 0 0 130px;
      max-width: 140px;
      @include mobileDevice {
        margin: 20px 0 0 0;
      }
    }
    &__button {
      margin: 16px 135px 0 auto;
      max-width: 136px;
      @include mobileDevice {
        margin: 8px 20px 0 auto;
      }
    }
    &__status {
      margin-top: 32px;
      width: 120px;
      display: flex;
      margin-right: 32px;
      font-size: 14px;
      line-height: 150%;
      color: $text-gray-redesign;
      @include mobileDevice {
        margin-top: 12px;
      }
    }
    &__answers {
      margin-left: 8px;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      color: $text-gray-redesign;
    }
    &__trailing {
      margin-left: auto;
      @include desktop {
        display: flex;
      }
    }
  }
}
