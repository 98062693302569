@import '../../styles/variables.scss';

.tab-layout-container {
  @include desktop {
    display: flex;
  }

  position: relative;
  min-height: 100vh;
  @include desktop {
    min-width: 100vh;
  }
}

.tab-layout {
  width: 100%;
  margin: 0;
  background-color: $container-background;

  &-overlay {
    background: black;
    opacity: 0.5;
    -moz-opacity: 0.5;
    -webkit-opacity: 0.5;
    filter: alpha(opacity=50);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include desktop {
      display: none;
    }
  }
  @include desktop {
    padding: 0 50px 50px 50px;
    margin-left: 280px;
  }
  @include mobileDevice {
    padding-top: 80px;
  }
}
