@import '../../styles/variables.scss';

.whatsnew {
  max-width: 770px;
  margin: 48px auto 0px auto;

  &__article {
    margin-bottom: 48px;

    &__date {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      padding-bottom: 12px;
      color: $text-gray;
    }

    &__title {
      font-weight: bold;
      font-size: 24px;
      line-height: 150%;
      padding-bottom: 24px;
      color: $text-black-2;
    }

    &__text {
      font-size: 16px;
      line-height: 175%;
      padding-bottom: 47px;
      color: $text-gray;
    }

    &__separator {
      height: 1px;
      background: $color-stroke;
    }
  }

  &__button + &__button {
    margin-top: 8px;
  }

  &__button-image {
    display: flex;
    max-height: 300px;
    flex-direction: column;
    gap: 12px;

    > img {
      border-radius: 4px;
    }
  }
}
