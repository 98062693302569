@import '../../styles/variables.scss';

.competencies {
  max-width: 100vw;

  @include desktop {
    margin-right: auto;
  }

  &__row {
    padding-bottom: 30px;

    display: grid;
    display: -ms-grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-columns-span: 4;
    grid-auto-flow: column;

    &__column {
      &__0 {
        -ms-grid-column: 1;
      }

      &__1 {
        -ms-grid-column: 2;
      }

      &__2 {
        -ms-grid-column: 3;
      }

      &__3 {
        -ms-grid-column: 4;
      }

      background: white;
      margin-right: 30px;
      max-width: 26vw;

      @include desktop {
        height: 195px;
      }

      box-sizing: border-box;
      box-shadow: 0px 12px 24px rgba(33, 103, 116, 0.06);
      border-radius: 5px;

      &:hover {
        cursor: pointer;
      }

      display: flex;
      flex-direction: column;
    }
  }

  &__index {
    font-weight: bold;
    font-size: 14px;
    padding: 20px;
    color: $color-teal;
  }

  &__grow {
    flex-grow: 3;
  }

  &__title {
    flex-grow: 1;
    padding: 0px 10px 0px 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    @include desktop {
      height: 112px;
      padding: 0px 20px 0px 20px;
    }
  }

  &__button {
    position: relative;
    border-top: $color-gray-separator-redesign 1px solid;
    padding: 22px 0;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    color: $color-brand;
    margin: 10px 20px 10px 15px;
    @include desktop {
      margin: 0px 20px 0px 20px;
    }
  }

  &__disclosure {
    margin: 2px 0px 0px 9px;
    height: 9px;
    width: 5px;
  }
}
