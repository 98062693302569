@import './tailwind.css';
@import 'https://fonts.googleapis.com/css?family=Roboto:400,500,700';

body {
  /* margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  color: #2b2d42;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-width: 100vw;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/**
 * Typography
 */
div {
  font-family: Roboto;
}

h1 {
  font-family: Roboto;
  font-size: 21px;
  color: #2b2d42;
  font-weight: bold;
}

@media (min-width: 1024px) {
  h1 {
    font-size: 38px;
  }
}

label {
  font-family: Roboto;
  font-size: 18px;
  color: #0c0f17;
  font-weight: bold;
}

a {
  color: #59aeb1;
}
