@import '../../styles/variables.scss';

.agenda-page {
  display: flex;
  justify-content: center;
  align-items: center;

  .agenda-card {
    max-width: 100%;
    padding: 90px 30px;

    @include desktop {
      max-width: 570px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 32px;
      padding-top: 32px;
      margin: 30px;
    }
  }

  button {
    letter-spacing: 0.12em;
  }

  .agenda {
    &__title {
      font-weight: bold;
      font-size: 32px;
      margin-bottom: 1rem;
    }

    &__desc {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 2.5rem;
    }

    &__item {
      position: relative;
      padding-left: 72px;
      margin-bottom: 29px;
    }

    &__itemTitle {
      font-weight: bold;
      font-size: 16px;
      padding-top: 3px;
      margin-bottom: 0.25rem;
    }

    &__itemDesc {
      font-weight: normal;
      font-size: 12px;
      padding-top: 4px;
    }

    &__bullet {
      position: absolute;
      top: 0;
      left: 0;
      width: 3rem;
      height: 3rem;
      background: $color-bullet-background;
      color: $color-brand;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: Roboto;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
