@import '../../styles/variables.scss';

.score {
  &__container {
    display: flex;
    flex-direction: row;
  }

  &__bullet {
    margin: 0 20px 0 24px;
    width: 64px;
    height: 64px;
    background: $text-teal-redesign;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bold;
    font-size: 14px;
  }

  &__info {
    &__result {
      width: 120px;
    }
    &__rating {
      padding-top: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      padding-right: 8px;
    }
    &__points {
      font-size: 12px;
      line-height: 150%;
      color: $text-gray;
    }
  }
}
