@import '../../styles/variables.scss';

button {
  text-transform: uppercase;
  max-height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;

  &.button-md {
    padding: 13px 20px;
    max-height: 40px;
  }

  &.button-lg {
    padding: 17px 30px;
    font-size: 12px;
  }

  &.button-xl {
    padding: 17px 30px;
    font-size: 12px;
    max-width: 250px;
  }

  &.button-xxl {
    padding: 17px 30px;
    font-size: 12px;
    max-width: 300px;
  }

  &.button-shadow {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }

  &[disabled] {
    cursor: not-allowed;
    background: #ccc;
    box-shadow: initial;
  }

  .button__text {
    font-weight: bold;
    font-size: 12px;
  }

  .button__icon-disclosure {
    width: 5px;
    height: 9px;
    margin-left: 10px;
  }

  .button__icon-external {
    width: 12px;
    height: 12px;
    margin-left: 9px;
  }

  .button__icon-disclosure-leading {
    width: 5px;
    height: 9px;
    margin-right: 10px;
  }

  .button__icon-close {
    width: 10px;
    height: 10px;
    margin-right: 11px;
  }

  &.button-inactive {
    cursor: default;
    opacity: 0.5;
    -moz-opacity: 0.5;
    -webkit-opacity: 0.5;
    filter: alpha(opacity=50);
  }

  &.button-green {
    background: $text-teal-redesign;
  }

  &.button-close {
    background: $color-red-light;
    color: $text-red-redesign;
  }
  &.button-transparent {
    background: none;
    color: $text-gray-redesign;
  }
  &.button-framed {
    background: transparent;
    border: 1px solid $color-gray-separator-redesign;
    color: $text-gray-redesign;
  }
  &.button-close-dark-red {
    background: $text-red-redesign;
    color: white;
  }
}
