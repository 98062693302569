@import '../../styles/variables.scss';

.test-row {
  &__test {
    @include desktop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    background-color: white;

    border-radius: 5px;

    margin-bottom: 16px;

    &:hover {
      cursor: pointer;
    }

    box-shadow: 0 12px 24px rgba(33, 103, 116, 0.06);
  }

  &__leading {
    display: flex;
    flex-direction: row;
    @include mobileDevice {
      border-bottom: 1px solid $border-grey-light;
      margin-bottom: 18px;
    }
  }

  &__trailing {
    @include desktop {
      display: flex;
      flex-direction: row;
    }
    @include mobileDevice {
      margin: 18px 0 0 0;
      padding-bottom: 20px;
    }
  }

  &__circle {
    background-color: #e0f1f4;
    border-radius: 24px;
    margin: 20px 24px 20px 32px;
    width: 48px;
    height: 48px;
    @include mobileDevice {
      margin-left: 18px;
    }
  }

  &__icon {
    display: block;
    height: 20px;
    width: auto;
    margin: 14px auto;
  }

  &__date {
    padding-top: 35px;
    font-size: 12px;
    margin-left: auto;
    margin-right: 90px;

    @include mobileDevice {
      margin: 0 0 18px 21px;
    }
  }

  &__calendar {
    width: 13px;
    height: 14px;
    margin-right: 11px;
  }

  &__status {
    font-weight: 500;
    font-size: 12px;

    margin-top: 34px;
  }

  &__status-container {
    margin-top: 34px;
    @include mobileDevice {
      margin-bottom: 18px;
      padding: 0 20px 18px 20px;
      border-bottom: 1px solid $border-grey-light;
    }
  }

  &__status-inprogress {
    color: $color-yellow;
  }
  &__status-completed {
    color: $color-teal;
  }

  &__testTitle {
    font-weight: bold;
    font-size: 16px;
    padding: 35px 0;
  }

  &__disclosure {
    border: 1px solid $color-gray-separator-redesign;
    box-sizing: border-box;
    border-radius: 3px;

    margin: 20px 32px 20px 110px;
    padding: 16px 42px;

    font-weight: bold;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.03em;
    color: $text-gray-redesign;

    @include mobileDevice {
      border-top: 1px solid $border-grey-light;
      margin: 18px 20px 0 20px;
    }
  }

  &__disclosure-continue {
    background-color: $text-teal-low-opacity;
    border: 0;
    color: $text-teal-redesign;
    padding: 16px 48px;
    margin: 20px 32px 20px 92px;
    @include mobileDevice {
      border-top: 1px solid $border-grey-light;
      margin: 18px 20px 0 20px;
    }
  }

  &__spacer {
    margin-left: 280px;
  }
}
