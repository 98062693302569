@import '../../styles/variables.scss';

.futureskills {
  &__button {
    @include mobileDevice {
      margin-top: 20px;
    }
    @include desktop {
      max-width: 187px;
    }
  }
}
